import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Comparateur from './pages/comparateur/comparateur';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path = ":compUrl" element = {<Comparateur />}> </Route>
        </Routes>
        {/* {errorMessage} */}
      </BrowserRouter>
    </div>
  );
}

export default App;
