import CustomSelect from "./../../components/inputs/customSelect";
import { useState, useEffect, useRef } from 'react';
import Projet from "./../../components/projet/projet";
import {
  useParams
} from 'react-router-dom';
import Medias from "./../../components/medias/medias";
import { createGlobalStyle } from 'styled-components';
import Credits from "./../../components/credits/credits";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faMagnifyingGlass, faPen, faArrowLeft, faArrowRight, faCamera
} from '@fortawesome/free-solid-svg-icons';
import { ReactSketchCanvas } from "react-sketch-canvas";
import ScreenCapture from 'react-screen-capture-v2';


const Comparateur = () => {

    // Properties
    const {compUrl} = useParams();
    const [datas, setDatas] = useState(null);
    const [currentDisplay, setCurrentDisplay] = useState("1 Projet");
    const [currentPov, setCurrentPov] = useState(null);
    const [compSelected, setCompSelected] = useState(null);
    const [compFollow, setCompFollow] = useState(null);
    const [projectList, setProjectList] = useState(null);
    const [color, setColor] = useState("#814747");
    const compList = useRef(["Projet 1"]);
    const [currentOption, setCurrentOption] = useState(null);
    const [penColor, setPenColor] = useState("black");
    const [penSize, setPenSize] = useState(2);
    const drawingZone = useRef(null);
    const [on, setOn] = useState(false);
    const [img, setImg] = useState("");  

    const GlobalStyles = createGlobalStyle`
        .mainColor, .display, .activ, .select-items {background-color : ${color}!important;}
        .activPrj {border : 2px solid ${color}!important;}
        .compZone {color : ${color};}
        .optionBtn:hover, .optionBtn.activ {background-color : ${color}!important; color : white}
        #zoomScreen {border: 4px solid ${color};}
        #drawPalette {border: 2px solid ${color};}
    `;


    // Effects
    useEffect(() => {
        fetch(`/programmes/${compUrl}/datas.json`)
        .then(response => response.json())
        .then(datas => setDatas(datas))
    }, [compUrl])

    useEffect(() => {
        if (datas) {
            setCurrentPov(Object.keys(datas.projets)[0]);   
            setColor(datas.color)     
        }
    }, [datas])

    useEffect(() => {
        if (datas && currentPov) {
            setProjectList(
                datas.projets[currentPov].map((p, i) => 
                    <Projet 
                        key = {p.name} 
                        team = {p.team} 
                        name = {p.name} 
                        activ = {compList.current.includes(i)} 
                        click = {() => setCompSelected(i)} 
                        image = {p.type === "img" ? `/programmes/${compUrl}/projets/${currentPov}/${p.name}_lr.jpg` : `/programmes/${compUrl}/projets/${currentPov}/${p.name}/jpg-156/000.jpg`} 
                    />
                )
            )
        }
    }, [datas, currentPov, compSelected, currentDisplay])

    useEffect(() => {
        setCurrentOption(null)
    }, [currentPov, currentDisplay])

    useEffect(() => {
        if (img) {
            const downloadLink = document.createElement('a');
            const fileName = `${datas.name}_screenshot.jpg`;
        
            downloadLink.href = img;
            downloadLink.download = fileName;
            downloadLink.click();
            setImg("");
            setOn(false);
        }
    }, [img])

    useEffect(() => {
        if (on) {
            if (currentOption === "zoom") {
                setCurrentOption(null)
            }
        }
    }, [on])

    useEffect(() => {
        if (currentOption === "zoom") {
            if (on) {
                setOn(false)
            }
        }
    }, [currentOption])

    useEffect(() => {
        var canvas_with_mask = document.querySelector("#react-sketch-canvas__stroke-group-0");
        if (canvas_with_mask) {
            canvas_with_mask.removeAttribute("mask");
        }
    }, [currentOption])

    
    // Render
    return (
        datas &&
        <>
            <ScreenCapture
                on={on}
                onEndCapture={(b64) => setImg(b64)}
            >
                <GlobalStyles />
                <div id="appUi">
                    <div id = "titleZone">
                        <h1 id="appTitle">{datas.name}</h1>
                        {datas.logo &&
                            <img src = {`/programmes/${compUrl}/logos/${datas.logo}`}/>
                        }
                    </div>

                    <CustomSelect 
                        options = {
                            [
                                {
                                    "name" : "1 Projet", 
                                    "preview" :  <div className = "preview">
                                        <div className = "display" style = {{width : "100%"}}></div>
                                    </div>
                                }, 
                                {
                                    "name" : "2 projets verticaux", 
                                    "preview" :  <div className = "preview">
                                        <div className = "display" style = {{width : "100%"}}></div>
                                        <div className = "display" style = {{width : "100%"}}></div>
                                    </div>
                                }, 
                                {
                                    "name" : "2 projets horizontaux", 
                                    "preview" :  <div className = "preview" style = {{flexDirection : "column"}}>
                                        <div className = "display" style = {{height : "100%"}}></div>
                                        <div className = "display" style = {{height : "100%"}}></div>
                                    </div>
                                }, 
                                {
                                    "name" : "4 projets", 
                                    "preview" :  <div className = "preview grid">
                                    <div className = "display"></div>
                                    <div className = "display"></div>
                                    <div className = "display"></div>
                                    <div className = "display"></div>
                                </div>
                                }
                            ]} 
                            setCurrent = {setCurrentDisplay}
                    /> 
                    
                    
                    {Object.keys(datas.projets).length > 1 &&    
                        <CustomSelect options = {Object.keys(datas.projets).map(prj => {return {"name" : prj, "preview" : <></>}})} setCurrent = {setCurrentPov}/> 
                    }
                    <div id="listeProjets">
                        {projectList}  
                    </div>



                </div>

                <Medias 
                    programme = {compUrl}
                    datas = {datas} 
                    currentDisplay = {currentDisplay} 
                    currentPov = {currentPov} 
                    compSelected = {compSelected} 
                    setCompSelected = {setCompSelected} 
                    compList = {compList}
                    currentOption = {currentOption}
                    setCurrentOption = {setCurrentOption}
                />

                {currentOption === "dessin" &&
                    <div style = {{position : "absolute", width : "100vw", height : "90vh"}}>
                        <ReactSketchCanvas
                            ref = {drawingZone}
                            style={{
                                position : "absolute",
                                background : "transparent",
                                cursor : "url(images/pen.png) 0 25, auto",
                            }}
                            width="100vw"
                            height="99vh"
                            canvasColor="transparent"
                            strokeWidth={penSize}
                            strokeColor={penColor}
                        />

                        <div id="drawPalette">
                            <h4>Dessin</h4>

                            <div id = "controllers">
                                <div id = "colors">
                                    <div className = {`${penColor === "white" ? "current" : ""}`} onClick = {() => {setPenColor("white")}} id = "white"></div>
                                    <div className = {`${penColor === "black" ? "current" : ""}`} onClick = {() => {setPenColor("black")}} id = "black"></div>
                                    <div className = {`${penColor === "red" ? "current" : ""}`} onClick = {() => {setPenColor("red")}} id = "red"></div>
                                    <div className = {`${penColor === "green" ? "current" : ""}`} onClick = {() => {setPenColor("green")}} id = "green"></div>
                                    <div className = {`${penColor === "blue" ? "current" : ""}`} onClick = {() => {setPenColor("blue")}} id = "blue"></div>
                                </div>

                                <div id = "sizes">
                                    <div className = {`${penSize === 2 ? "current" : ""}`} onClick = {() => {setPenSize(2)}} id = "s2"></div>
                                    <div className = {`${penSize === 4 ? "current" : ""}`} onClick = {() => {setPenSize(4)}} id = "s4"></div>
                                    <div className = {`${penSize === 12 ? "current" : ""}`} onClick = {() => {setPenSize(12)}} id = "s12"></div>
                                </div>

                                <div id = "controls">
                                    <div onClick = {() => {drawingZone.current.undo()}} id = "white"><FontAwesomeIcon icon={faArrowLeft}/></div>
                                    <div onClick = {() => {drawingZone.current.redo()}} id = "black"><FontAwesomeIcon icon={faArrowRight}/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            

                {compFollow}

                <Credits /> 
            </ScreenCapture>
            
            <div id = "options">
                <div className = {`optionBtn ${currentOption === "zoom" ? "activ" : ""}`} onClick={() => {setCurrentOption(c => c === "zoom" ? null : "zoom")}}>
                    <FontAwesomeIcon icon={faMagnifyingGlass}/>
                    <p className = "btnTextHover">Zoomer</p>
                </div>
                <div className = {`optionBtn ${currentOption === "dessin" ? "activ" : ""}`} onClick={() => {setCurrentOption(c => c === "dessin" ? null : "dessin")}}>
                    <FontAwesomeIcon icon={faPen}/>
                    <p className = "btnTextHover">Dessiner</p>
                </div>
                <div className = {`optionBtn ${on ? "activ" : ""}`} onClick={() => setOn(!on)}>
                    <FontAwesomeIcon icon={faCamera}/>
                    <p className = "btnTextHover">Capturer</p>
                </div>
                
        </div>
        </>
  )
}

export default Comparateur;