import { useState, useEffect, useRef } from 'react';

const Medias = ({programme, datas, currentDisplay, currentPov, compSelected, setCompSelected, compList, currentOption, setCurrentOption}) => {

    const standardRatio = 1920 / 1080; // ratio standard
    const zoom = 1;
    // Refs
    const playerRef = useRef();
    const canvasARef = useRef(null);
    const canvasBRef = useRef(null);
    const canvasCRef = useRef(null);
    const canvasDRef = useRef(null);  
    const canvasFullRef = useRef(null);
    const canvasZoomRef = useRef(null);
    const playerA = useRef(null);
    const playerB = useRef(null);
    const playerC = useRef(null);
    const playerD = useRef(null); 
    const playerFull = useRef(null);
    const playerZoom = useRef(null);

    // States
    const [compA, setCompA] = useState(null);
    const [compB, setCompB] = useState(null);
    const [compC, setCompC] = useState(null);
    const [compD, setCompD] = useState(null); 
    // Players
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [frame, setFrame] = useState(0);
    const [render, setRender] = useState(null); // image a rendre
    const [offsetWidth, setOffsetWidth] = useState(0); 
    const [offsetHeight, setOffsetHeight] = useState(0); 
    const [sizeWidth, setSizeWidth] = useState(0); 
    const [sizeHeight, setSizeHeight] = useState(0); 
    const [currentSpeed, setCurrentSpeed] = useState(0); // vitesse de la rotation
    const [startSpeed, setStartSpeed] = useState(0); // vitesse au lancement d'une rotation
    const [rotation, setRotation] = useState(0);
    const isDraggingOnMouse = useRef(false);
    let clientX = 0;
    let accStartTime = null;
    let accStartX = null;
    let targetRotation = null;
    let accStartDir = false;
    let direction = true;
    const [directionSt, setDirectionSt] = useState("droite");
    const mouseX = useRef(0);
    const mouseXOnMouseDown = useRef(0);
    const targetRotationOnMouseDown = useRef(frame);
    const frameRef = useRef(0);
    const [resolution , setResolution] = useState("jpg-720");
    const [resolutionFull, setResolutionFull] = useState("jpg-1080");
    const [xZoomPos, setXZoomPos] = useState(0);
    const [yZoomPos, setYZoomPos] = useState(0);
    const [isCanvasHovered, setIsCanvasHovered] = useState(false)
    const [canvasOffset, setCanvasOffset] = useState([0, 0]);
    const [hoveredCanvas, setHoveredCanvas] = useState(null)
    
    
    // Functions
    const resetComps = () => {
        setCompA(null);
        setCompB(null);
        setCompC(null);
        setCompD(null);
        
        compList.current = [];
      }

    const convertFrame = (frame) => { // Fonction pour obtenir le numero de frame en str
        return frame.toLocaleString('en-US', {minimumIntegerDigits: 3, useGrouping:false})
    }

    const checkComp = (toAdd) => {
        if ([compA, compB, compC, compD].includes(toAdd)) {return false}
        return true; 
    }

    const setNewComp = (elem) => {
        if (compSelected !== null) {
          if(checkComp(compSelected)) {
            compList.current = [];
            if (elem === "A") {setCompA(compSelected)}
            if (elem === "B") {setCompB(compSelected)}
            if (elem === "C") {setCompC(compSelected)}
            if (elem === "D") {setCompD(compSelected)}
          } else {
            // setErrorMessage(<ErrorMessage msg = "impossible d'ajouter l'element. Il ne peut se trouver qu'une fois dans la liste" close = {() => setErrorMessage(null)}/>)
          }
    
          setCompSelected(null);
        }
    }

    useEffect(() => {
        if (currentDisplay === "1 Projet") {
            setResolution(currentSpeed !== 0 ? "jpg-450" : "jpg-1080")
        } else {
            setResolution(currentSpeed !== 0 ? "jpg-156" : "jpg-720")
        }
        setResolutionFull(currentSpeed !== 0 ? "jpg-450" : "jpg-1080")
    }, [currentDisplay, currentSpeed])


    // Effects
    useEffect(() => {
        const onTouchmove = (ev) => {

            if (ev.target.className.includes("navigationChoice")) {
                return;
            }
  
            const x = ev.touches[0].screenX
  
            clientX = x;
  
            var mouseXTmp = x - window.innerWidth / 2;
  
            direction = 'gauche';
            setDirectionSt('gauche')
            if ((mouseX.current - mouseXTmp) < 0) {
                direction = 'droite';
                setDirectionSt('droite')
            }
            if (direction != accStartDir) {
                accStartDir = direction;
                accStartTime = new Date().getTime();
                accStartX = clientX;
            }
            mouseX.current = mouseXTmp;
  
            // calcul la rotation en fonction de la distance parcourue
            var largeurCanvas = playerRef.current.offsetWidth;
            var distance = (mouseX.current - mouseXOnMouseDown.current) / .3; // correction de la vitesse
            var angle = distance * 180 / largeurCanvas;
            targetRotation = (targetRotationOnMouseDown.current + angle) % 360;
            targetRotation = Math.floor(targetRotation);
            if (targetRotation > 360) {
                targetRotation = targetRotation - 360;
            }
            if (targetRotation < 0) {
                targetRotation = targetRotation + 360;
            }
            setRotation(targetRotation);
  
            var time = new Date().getTime(); // temps en ms
            var tempsEcoule = time - accStartTime; // temps écoulé en ms
            var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
            var rayonRotation = playerRef.current.offsetWidth;
            var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
            var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
            // fixe un maximum
            if (vitesseAngulaire > 8) {
                vitesseAngulaire = 8;
            }
            setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
        }
  
        const onTouchEnd = (ev) => {
            // calcule le temps et la distance parcourue depuis le dernier changement de direction
            var time = new Date().getTime(); // temps en ms
            var tempsEcoule = time - accStartTime; // temps écoulé en ms
            var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
  
            var rayonRotation = playerRef.current.offsetWidth;
            var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
            var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
  
            // fixe un maximum
            if (vitesseAngulaire > 8) {
                vitesseAngulaire = 8;
            }
  
            setCurrentSpeed(vitesseAngulaire);
            setStartSpeed(vitesseAngulaire);
        }
  
        const onTouchStart = (ev) => {
  
            // securites
            if (typeof window.getSelection === 'function'
                && typeof window.getSelection().empty === 'function') {
                window.getSelection().empty();
            }
  
            // Pour les tactiles on tourne avec un doigt, on déplace avec deux
            if (ev.touches) {
                if (ev.touches.length > 1) {
                    return; // Pas le cas sur D3d
                }
            }
  
            const x = ev.touches[0].screenX
            const y = ev.touches[0].screenY
  
            setStartSpeed(0);
            setCurrentSpeed(0);
            clientX = x;
            accStartTime = new Date().getTime();
            accStartX = x;
    
            targetRotation = rotation;
            mouseXOnMouseDown.current = x - window.innerWidth / 2;
  
        }
  
        const onMouseMove = (ev) => {

            setIsCanvasHovered(ev.target.tagName === "CANVAS")
            // gestion du zoom 
            if (ev.target.tagName === "CANVAS") {
                setXZoomPos(ev.screenX > window.screen.width ? ev.screenX - window.screen.width : ev.screenX)
                setYZoomPos(ev.screenY -(window.outerHeight - document.body.offsetHeight))
                if (ev.target.id === "canvasFull") {

                    setCanvasOffset([ev.offsetX/document.body.clientWidth, ev.clientY/document.body.clientHeight])
                } else {
                    setCanvasOffset([ev.offsetX/ev.target.width, ev.offsetY/ev.target.height])
                }





                setHoveredCanvas(ev.target.id)                
            } else {
                setHoveredCanvas(null)
            }

            if (isDraggingOnMouse.current) {
  
                const x = ev.screenX
  
                clientX = x;
  
                var mouseXTmp = x - window.innerWidth / 2;
  
                direction = 'gauche';
                setDirectionSt('gauche')
                if ((mouseX.current - mouseXTmp) < 0) {
                    direction = 'droite';
                    setDirectionSt('droite')
                }
  
                if (direction != accStartDir) {
                    accStartDir = direction;
                    accStartTime = new Date().getTime();
                    accStartX = clientX;
                }
                mouseX.current = mouseXTmp;
  
                // calcule la rotation en fonction de la distance parcourue
                var largeurCanvas = playerRef.current.offsetWidth;
                var distance = (mouseX.current - mouseXOnMouseDown.current) / .3; // correction de la vitesse
                var angle = distance * 180 / largeurCanvas;

                targetRotation = ((360 / 90 * targetRotationOnMouseDown.current) + angle) % 360;
                targetRotation = Math.floor(targetRotation);

                if (targetRotation > 360) {
                    targetRotation = targetRotation - 360;
                }
                if (targetRotation < 0) {
                    targetRotation = targetRotation + 360;
                }
                setRotation(targetRotation);
  
                var time = new Date().getTime(); // temps en ms
                var tempsEcoule = time - accStartTime; // temps écoulé en ms
                var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
                var rayonRotation = playerRef.current.offsetWidth;
                var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
                // fixe un maximum
                if (vitesseAngulaire > 8) {
                    vitesseAngulaire = 8;
                }
                setCurrentSpeed(vitesseAngulaire !== isNaN ? vitesseAngulaire : 0);
            }
        }
  
        const onMouseUp = (ev) => {
            // calcule le temps et la distance parcourue depuis le dernier changement de direction

            if (isDraggingOnMouse.current) {
                var time = new Date().getTime(); // temps en ms
                var tempsEcoule = time - accStartTime; // temps écoulé en ms
                var distance = Math.abs(accStartX - clientX); // distance parcourue en pixels
      
                if (playerRef.current) {
                    var rayonRotation = playerRef.current.offsetWidth;
                    var vitesse = (distance / tempsEcoule) * 1000; // vitesse en px/s
                    var vitesseAngulaire = (vitesse / rayonRotation) * 2 * Math.PI; // °/s
                }
      
                // fixe un maximum
                if (vitesseAngulaire > 8) {
                    vitesseAngulaire = 8;
                }
      
                setCurrentSpeed(vitesseAngulaire);
                setStartSpeed(vitesseAngulaire);
                isDraggingOnMouse.current = false;
            }
           
        }
  
        const onMouseDown = (ev) => {
            // securites
            if (typeof window.getSelection === 'function'
                && typeof window.getSelection().empty === 'function') {
                window.getSelection().empty();
            }

            if (currentOption !== null) {return}
            
            targetRotationOnMouseDown.current = frameRef.current
  
            // Pour les tactiles on tourne avec un doigt, on déplace avec deux
            const x = ev.screenX
            const y = ev.screenY
  
            setStartSpeed(0);
            setCurrentSpeed(0);
            clientX = x;
            accStartTime = new Date().getTime();
            accStartX = x;
    
            targetRotation = rotation;
            mouseXOnMouseDown.current = x - window.innerWidth / 2;
            isDraggingOnMouse.current = true;
        }
  
        if (playerRef.current && playerRef.current) {
  
            /// Lets - go 
            playerRef.current.addEventListener("touchmove", onTouchmove);
            playerRef.current.addEventListener("touchend", onTouchEnd);
            playerRef.current.addEventListener("touchstart", onTouchStart);
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
            playerRef.current.addEventListener("mousedown", onMouseDown);
  
            return () => {
                if (playerRef.current && playerRef.current) {
                    playerRef.current.removeEventListener("touchmove", onTouchmove);
                    playerRef.current.removeEventListener("touchend", onTouchEnd);
                    playerRef.current.removeEventListener("touchstart", onTouchStart);
                    window.removeEventListener("mousemove", onMouseMove);
                    window.removeEventListener("mouseup", onMouseUp);
                    playerRef.current.removeEventListener("mousedown", onMouseDown);   
                }
            }
        }
    }, [currentOption])


    useEffect(() => {
        resetComps();
    }, [currentPov])

    useEffect(() => {
        if (currentDisplay === "1 Projet") {
            setCompB(null);
            setCompC(null);
            setCompD(null);
        } else if (currentDisplay.includes("2 projets")) {
            setCompC(null);
            setCompD(null);
        } 
    }, [currentDisplay])


    useEffect(() => {
        
        var step = Math.floor((rotation * 90 / 360));
        setFrame(step);
        frameRef.current = step;
    }, [rotation])
    
    useEffect(() => {
    
      const easeOut = (s) => {
          return s * 0.90
          // return c * ((t = t / d - 1) * t * ((factor + 1) * t + factor) + 1) + b;
      };
    
      if (startSpeed > 0 && currentSpeed > 0) {
    
          const angle = (25 / 20) * currentSpeed;
          const value = easeOut(currentSpeed);
    
          setRotation((r) => {
              let rSave = r;
              
              if (directionSt == 'droite') {
                  rSave += angle;
              } else {
                  rSave -= angle;
              }
              rSave = Math.floor(rSave);
              if (rSave > 360) {
                  rSave = rSave - 360;
              }
              if (rSave < 0) {
                  rSave = rSave + 360;
              }
              return rSave
          })
    
          setTimeout(() => {
              if (value < 0.5) {
                  setCurrentSpeed(0);
              } else {
                  setCurrentSpeed(value);
              }
          }, 5)
    
      } 
    }, [currentSpeed, startSpeed])

    useEffect(() => {
        compList.current = []
        if (compA !== null) {
            canvasARef.current.height = playerA.current.offsetHeight; 
            canvasARef.current.width = canvasARef.current.height * standardRatio;
            compList.current.push(compA);
        }
        if (compB !== null) {
            canvasBRef.current.height = playerB.current.offsetHeight; 
            canvasBRef.current.width = canvasBRef.current.height * standardRatio;
            compList.current.push(compB);
        }
        if (compC !== null) {
            canvasCRef.current.height = playerC.current.offsetHeight; 
            canvasCRef.current.width = canvasCRef.current.height * standardRatio;
            compList.current.push(compC);
        }
        if (compD !== null) {
            canvasDRef.current.height = playerD.current.offsetHeight; 
            canvasDRef.current.width = canvasDRef.current.height * standardRatio;
            compList.current.push(compD);
        }
        if (isFullScreen !== false) {
            canvasFullRef.current.height = playerFull.current.offsetHeight; 
            canvasFullRef.current.width = canvasFullRef.current.height * standardRatio 
        }
        if (currentOption === "zoom") {
            canvasZoomRef.current.height = 300 
            canvasZoomRef.current.width = 400 
        }
    }, [compA, compB, compC, compD, isFullScreen, currentOption])
    
    useEffect(() => {
    
        const getPlayerSizes = (canvas, parent) => { // Fonction pour obtenir les dimensions ecrans du player
        
          const offsetX = -(canvas.width*zoom - parent.offsetWidth) / 2;
          const offsetY = -(canvas.height*zoom - parent.offsetHeight) / 2;    
      
          return [offsetX, offsetY, canvas.width, canvas.height]
        }

        if (isFullScreen !== false) {
            const contextFull = canvasFullRef.current.getContext('2d');
            const imgFull = new Image();
            let compFull = null;

            switch (isFullScreen) {
                case 0 :
                    compFull = compA;
                    break
                case 1 :
                    compFull = compB;
                    break
                case 2 :
                    compFull = compC;
                    break
                case 3 :
                    compFull = compD;
                    break
            }


            if (currentOption === "zoom" && hoveredCanvas) {

                const contextZoom = canvasZoomRef.current.getContext('2d');
                const imgZoom = new Image();

                if (datas.projets[currentPov][compFull].type === "img") {
                    imgZoom.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compFull].name}.jpg`;
                } else {
                    imgZoom.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compFull].name}/jpg-2160/${convertFrame(frame)}.jpg`;
                }
    
                imgZoom.addEventListener("load", () => {

                    contextZoom.drawImage(
                        imgZoom, 
                        (-canvasOffset[0]*4096)+200 < 0 ? ((-canvasOffset[0]*4096)+200 > -3696 ? (-canvasOffset[0]*4096)+200 : -3696) : 0,
                        (-canvasOffset[1]*2160)+150 < 0 ? ((-canvasOffset[1]*2160)+150 > -1860 ? (-canvasOffset[1]*2160)+150 : -1860) : 0,
                        4096, 
                        2160
                    )
                })
            }


            const [offsetXFull, offsetYFull, canvasWidthFull, canvasHeightFull] = getPlayerSizes(canvasFullRef.current, playerFull.current);

            if (datas.projets[currentPov][compFull].type === "img") {
                imgFull.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compFull].name}.jpg`;
            } else {
                imgFull.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compFull].name}/${resolutionFull}/${convertFrame(frame)}.jpg`;
            }
            imgFull.addEventListener("load", () => {  
                contextFull.drawImage(imgFull, 0, 0, canvasWidthFull, canvasHeightFull)
            })
        } else {
            if (compA !== null) {
                const contextA = canvasARef.current.getContext('2d');
                const imgA = new Image();
                const [offsetXA, offsetYA, canvasWidthA, canvasHeightA] = getPlayerSizes(canvasARef.current, playerA.current);
                if (datas.projets[currentPov][compA].type === "img") {
                    imgA.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compA].name}.jpg`;
                } else {
                    imgA.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compA].name}/${resolution}/${convertFrame(frame)}.jpg`;
                }
    
                imgA.addEventListener("load", () => {
                contextA.drawImage(imgA, 0, 0, canvasWidthA+50, canvasHeightA)
                })
            }
            if (compB !== null) {
                const contextB = canvasBRef.current.getContext('2d');
                const imgB = new Image();
                const [offsetXB, offsetYB, canvasWidthB, canvasHeightB] = getPlayerSizes(canvasBRef.current, playerB.current);
                if (datas.projets[currentPov][compB].type === "img") {
                    imgB.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compB].name}.jpg`;
                } else {
                    imgB.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compB].name}/${resolution}/${convertFrame(frame)}.jpg`;
                }                
                
                imgB.addEventListener("load", () => {  
                contextB.drawImage(imgB, 0, 0, canvasWidthB, canvasHeightB)
                })
            }
            if (compC !== null) {
                const contextC = canvasCRef.current.getContext('2d');
                const imgC = new Image();
                const [offsetXC, offsetYC, canvasWidthC, canvasHeightC] = getPlayerSizes(canvasCRef.current, playerC.current);
                
                if (datas.projets[currentPov][compC].type === "img") {
                    imgC.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compC].name}.jpg`;
                } else {
                    imgC.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compC].name}/${resolution}/${convertFrame(frame)}.jpg`;
                }                
                
                imgC.addEventListener("load", () => {  
                contextC.drawImage(imgC, 0, 0, canvasWidthC, canvasHeightC)
              })
            }
            if (compD !== null) {
                const contextD = canvasDRef.current.getContext('2d');
                const imgD = new Image();
                const [offsetXD, offsetYD, canvasWidthD, canvasHeightD] = getPlayerSizes(canvasDRef.current, playerD.current);
                if (datas.projets[currentPov][compD].type === "img") {
                    imgD.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compD].name}.jpg`;
                } else {
                    imgD.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][compD].name}/${resolution}/${convertFrame(frame)}.jpg`;
                }                
                
                imgD.addEventListener("load", () => {  
                contextD.drawImage(imgD, 0, 0, canvasWidthD, canvasHeightD)
                })
            }
            if (currentOption === "zoom" && hoveredCanvas) {

                const contextZoom = canvasZoomRef.current.getContext('2d');
                const imgZoom = new Image();

                let correctImg = compA;
                if (hoveredCanvas === "canvasB") {correctImg = compB}
                else if (hoveredCanvas === "canvasC") {correctImg = compC}
                else if (hoveredCanvas === "canvasD") {correctImg = compD}

                if (datas.projets[currentPov][correctImg].type === "img") {
                    imgZoom.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][correctImg].name}.jpg`;
                } else {
                    imgZoom.src = `programmes/${programme}/projets/${currentPov}/${datas.projets[currentPov][correctImg].name}/jpg-2160/${convertFrame(frame)}.jpg`;
                }
    
                imgZoom.addEventListener("load", () => {

                    contextZoom.drawImage(
                        imgZoom, 
                        (-canvasOffset[0]*4096)+200 < 0 ? ((-canvasOffset[0]*4096)+200 > -3696 ? (-canvasOffset[0]*4096)+200 : -3696) : 0,
                        (-canvasOffset[1]*2160)+150 < 0 ? ((-canvasOffset[1]*2160)+150 > -1860 ? (-canvasOffset[1]*2160)+150 : -1860) : 0,
                        4096, 
                        2160
                    )
                })
            }
        }
    

    }, [frame, compA, compB, compC, compD, resolution, resolutionFull, isFullScreen, currentOption, canvasOffset, hoveredCanvas])

    // useEffect(() => {
    //     if (isFullScreen !== false) {
    //         setCurrentOption(null)
    //     }
    // }, [isFullScreen])


    // Render
    return (
        <div 
            id="appMedias" 
            ref = {playerRef} 
            className = {
                currentDisplay === "1 Projet" ? "one" :
                currentDisplay === "2 projets horizontaux" ? "two" :
                currentDisplay === "2 projets verticaux" ? "three" : "four"
            }
        >
            
        <div ref = {playerA} className="compZone" onDragOver = {(e) => {e.preventDefault()}} onDrop = {() => setNewComp("A")}>
            {compA !== null ? <>
                    <canvas ref = {canvasARef} id = "canvasA"/>
                    <div className = "legend">{`${datas.projets[currentPov][compA].team} - ${datas.projets[currentPov][compA].name}`}</div>
                    <div className = "setFullScreenPlayer" onClick = {() => {setIsFullScreen(0)}}><img src = "/images/startFullScreen.png"/></div>
                </>
                : "Glissez un projet à comparer"}
        </div>

          {(currentDisplay === "2 projets verticaux" || currentDisplay === "2 projets horizontaux" || currentDisplay === "4 projets") &&
            <div ref = {playerB} className="compZone" onDragOver = {(e) => {e.preventDefault()}} onDrop = {() => setNewComp("B")}>
              {compB !== null ? <>
                    <canvas ref = {canvasBRef} id = "canvasB"/>
                    <div className = "legend">{`${datas.projets[currentPov][compB].team} - ${datas.projets[currentPov][compB].name}`}</div>
                    <div className = "setFullScreenPlayer" onClick = {() => {setIsFullScreen(1)}}><img src = "/images/startFullScreen.png"/></div>
                </>
              : "Glissez un projet à comparer"}
            </div>
          }

          {currentDisplay === "4 projets" &&
            <div ref = {playerC} className="compZone" onDragOver = {(e) => {e.preventDefault()}} onDrop = {() => setNewComp("C")}>
              {compC !== null ? <>
                    <canvas ref = {canvasCRef} id = "canvasC"/>
                    <div className = "legend">{`${datas.projets[currentPov][compC].team} - ${datas.projets[currentPov][compC].name}`}</div>
                    <div className = "setFullScreenPlayer" onClick = {() => {setIsFullScreen(2)}}><img src = "/images/startFullScreen.png"/></div>
                </>
              : "Glissez un projet à comparer"}
            </div>
          }

          {currentDisplay === "4 projets" &&
            <div ref = {playerD} className="compZone" onDragOver = {(e) => {e.preventDefault()}} onDrop = {() => setNewComp("D")}>
              {compD !== null ? <>
                    <canvas ref = {canvasDRef} id = "canvasD"/>
                    <div className = "legend">{`${datas.projets[currentPov][compD].team} - ${datas.projets[currentPov][compD].name}`}</div>
                    <div className = "setFullScreenPlayer" onClick = {() => {setIsFullScreen(3)}}><img src = "/images/startFullScreen.png"/></div>
                </>
              : "Glissez un projet à comparer"}
              
            </div>
          }

        {isFullScreen !== false &&
            <div ref = {playerFull} className="compFull">
                <canvas ref = {canvasFullRef} id = "canvasFull"/>
                <div className = "legend">{`${datas.projets[currentPov][[compA, compB, compC, compD][isFullScreen]].team} - ${datas.projets[currentPov][[compA, compB, compC, compD][isFullScreen]].name}`}</div>
                <div className = "setFullScreenPlayer" onClick = {() => {setIsFullScreen(false)}}><img src = "/images/stopFullScreen.png"/></div>
            </div>
        }

        {currentOption === "zoom" &&
            <div id = "zoomScreen" style = {{
                    top : `${yZoomPos < window.screen.height-500 ? yZoomPos : window.screen.height-500}px`, 
                    left : `${xZoomPos < window.screen.width-440 ? xZoomPos : window.screen.width-440}px`, 
                    display : `${isCanvasHovered ? "block" : "none"}`
                }}>
                <div ref = {playerZoom} className="compZoom">
                    <canvas ref = {canvasZoomRef}/>
                </div>
            </div>
        }

        </div>
    )
}

export default Medias;