
// ////////////////////////////////////////////////////////////////////////
// Imports

// React
import React, {useState, useEffect, useRef} from 'react';


const CustomSelect = ({options, preselected = null, setCurrent = () => {}}) => {


    const customSelectRef = useRef();
    const selectorRef = useRef();
    const [displayOptions, setDisplayOptions] = useState(false)
    const [currentSelection, setCurrentSelection] = useState(preselected ? preselected : options[0].name)

    useEffect(() => {
        setCurrent(currentSelection)
    }, [currentSelection])

    useEffect(() => {
        window.addEventListener('click', (e) => {
            if(e.target !== selectorRef.current) {
                setDisplayOptions(false)
            }
        })
    }, [])

    return (
        <div className = "custom-select" ref = {customSelectRef}>

            <div 
                className = {`select-selected ${displayOptions ? 'activ' : ''}`} 
                onClick = {() => {setDisplayOptions(d => !d)}}
                style = {{
                    borderRadius : !displayOptions ? "14px" : "14px 14px 0px 0px"
                }}
                ref = {selectorRef}
            >
                {currentSelection}
            </div>

            <div className = {`select-items ${displayOptions ? '' : 'select-hide'}`}>
                {options.map((opt, i) => 
                    <div key = {`opt-${i}`} className = {`opt mainHover ${opt.name === currentSelection ? "selectedOne" : ""}`} onClick = {() => {
                        setCurrentSelection(opt.name);
                        setDisplayOptions(false);
                    }}>
                        <div>{opt.name}</div>
                        {opt.preview}
                    </div>
                )}
            </div>

        </div>
    )
}



// ////////////////////////////////////////////////////////////////////////
// Export
export default CustomSelect;