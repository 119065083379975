const Projet = ({team, name, click, activ, image = null}) => {
    return (
      <div 
        className = {`projet ${activ ? "activPrj" : ""}`} 
        // onClick = {click} 
        onDrag = {click}
        draggable
      >
        <div className = "projetDatas">
          <p className = "projetTeam">{team}</p>
          <p className = "projetName">{name}</p>
        </div>
        {image &&
          <div className = "projetMedia">
            <img src = {image}></img>
          </div>
          
        }
      </div>
    )
}

export default Projet;